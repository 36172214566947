/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    debugger;
    state.PropertySpecs.unshift(item)
  },
  SET_PropertySpecs(state, PropertySpecs) {
    debugger;
    try {

      state.PropertySpecs = PropertySpecs;

    } catch (error) {
      console.error("Failed to parse PropertySpecs data JSON:", error);
      state.PropertySpecs = [];
    }
  },
  UPDATE_PropertySpecs(state, PropertySpecs) {
    debugger
    const PropertySpecsIndex = state.PropertySpecs.findIndex((p) => p.Id == PropertySpecs.Id)
    Object.assign(state.PropertySpecs[PropertySpecsIndex], PropertySpecs)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.PropertySpecs.findIndex((p) => p.ID == itemId)
      state.PropertySpecs.splice(ItemIndex, 1)
  },
}
