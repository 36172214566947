<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: {{$t('AddNew')}} Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar  position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <div class="mt-6 flex items-center justify-between px-6">
        <h4 style="color: white;">{{ Object.entries(this.data).length === 0 ? $t("AddPS") : $t("uppPS") }}</h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">

      <div class="p-6">
        <!-- NAME -->
        <label style="font-weight: bold;color: white;">{{$t("NameAr")}}</label>
        <vs-input  v-model="NameAr" class="mt-5 w-full" name="NameAR"/>
        <span class="text-danger text-sm" v-show="errors.has('nameAR')">{{ errors.first('nameAR') }}</span>
      </div>
      <div class="p-6">
        <!-- NAME -->
        <label style="font-weight: bold;color: white;">{{$t("NameEn")}}</label>
        <vs-input  v-model="NameEn" class="mt-5 w-full" name="NameEN"/>
        <span class="text-danger text-sm" v-show="errors.has('NameEN')">{{ errors.first('NameEN') }}</span>
      </div>
      <div class="p-6">
        <!-- اختيار الفئة -->
        <label style="font-weight: bold;color: white;">{{ $t("Category")  }}</label>
          <v-select style="color: black;"
          label="NameAr" 
            class="mt-5 w-full sclass"
            name="NameAr" 
            v-model="selectedCategory"
            :reduce="Category => Category.ID"
            :options="adscategories"
            
          />
        </div>
      <div class="p-6">
       
       <div class=" w-full">
       <label style="font-weight: bold;color: white;">{{ $t("PropertyName") }}</label>
       <v-select
         label="NameAr"
         class="mt-2 w-full sclass"
         name="NameAr"
         v-model="PropertyId"
         :reduce="Property => Property.ID"
         :options="properties"
       />
     </div>
     
     <span style="color: white;" class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
   </div>
      <div class="flex flex-wrap items-center p-6">
        <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid" style="background-color: aliceblue; color: black; font-weight: bold;" color="info">{{$t("Save")}}</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Cancel")}}</vs-button>
      </div>


    </VuePerfectScrollbar>


  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moduleproperties from "@/store/settings/properties/moduleproperties.js";
import moduleAdsCatgeory from "@/store/AdsCatgeory/moduleAdsCatgeory.js";
export default {
  components: {
    VuePerfectScrollbar
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },

  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { ID, NameAr, NameEn,PropertyId} = JSON.parse(
          JSON.stringify(this.data)
        );
        this.ID = ID;
        this.NameAr = NameAr;
        this.NameEn = NameEn;
        this.PropertyId = PropertyId;
        //this.selectedCategory=AdsCategoryId;
        //this.Code = Code;
        //this.FormatPhone = FormatPhone;

      }

    }
  },
  data() {
    return {
      //properties: [],
      ID: 0,
      NameAr: "",
      NameEn: "",
      PropertyId:"",
      selectedCategory:0,
     // Code: "",
      //FormatPhone:"",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");

        }
      }
    },
    adscategories() {
      debugger;
      console.log("erriii",this.$store.state.AdsCatgeoryList.AdsCatgeorys);
      return this.$store.state.AdsCatgeoryList.AdsCatgeorys;
    },
    isFormValid() {
      return !this.errors.any() && this.NameAR,this.PropertyId ;
    },
    properties() {
      debugger;
      console.log("الفئة المختارة:", this.selectedCategory);
    const filteredproperties = this.$store.state.propertyList.propertiess;
    console.log("الخصائص من Vuex:", filteredproperties);
    
    if (!this.selectedCategory) {
      return [];  // إعادة مصفوفة فارغة إذا لم يتم اختيار فئة
    }

    return filteredproperties.filter(property => property.AdsCategoryId === this.selectedCategory);
  },
  },
  methods: {
    initValues() {
      if (this.data.Id) return;
      this.ID = 0;
      this.NameAr = "";
      this.NameEn = "";
      this.PropertyId = 0;
      // this.Code = "";
      //this.FormatPhone ="";
    },
    submitData() {
      debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          debugger;
          const obj = {
            ID: this.ID,
            NameAr: this.NameAr,
            NameEn: this.NameEn,
            PropertyId: this.PropertyId,
            // CodeNumber: this.Code,
            //FormatPhone:this.FormatPhone,
          };
debugger;
          if (this.ID !== null && this.ID > 0) {
            debugger;
            this.$store.dispatch("PropertySpecsList/updateItem", obj).then(() => {
                this.$store.dispatch("PropertySpecsList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            delete obj.ID;
            obj.popularity = 0;
            this.$store
              .dispatch("PropertySpecsList/addItem", obj)
              .then(() => {
                this.$store.dispatch("PropertySpecsList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }

          this.$emit("closeSidebar");
          this.initValues();
        }
      });
    },
  },
  created() {

if (!moduleproperties.isRegistered) {
  this.$store.registerModule("propertyList", moduleproperties);
  moduleproperties.isRegistered = true;
}
debugger;
this.$store.dispatch("propertyList/fetchDataListItems");
debugger;
    if (!moduleAdsCatgeory.isRegistered) {
      this.$store.registerModule("AdsCatgeoryList", moduleAdsCatgeory);
      moduleAdsCatgeory.isRegistered = true;
    }
    debugger;
    this.$store.dispatch("AdsCatgeoryList/fetchDataListItems");
    
},
mounted() {
this.isMounted = true;
},
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}

.sclass{
  background-color: white !important;
}
</style>
